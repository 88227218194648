@import "styles/variables";

div.categoryTabs {
  display: none;
}

aside.categoryWidgetFixed {
  position: fixed;
  left: 0;
  top: 15%;
  border-radius: 0 10px 10px 0;
  z-index: 100;
  box-shadow: 0 0 5px $c_shadow;
  background-color: $c_white_bg;

  ul {
    li {
      position: relative;
      width: 64px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      //transition: all .2s ease;

      &:first-child {
        border-top-right-radius: $b_radius_default;

        &:hover {
          border-top-right-radius: 0;
        }
      }

      &:last-child {
        border-bottom-right-radius: $b_radius_default;

        &:hover {
          border-bottom-right-radius: 0;
        }
      }

      &.discount {
        background-color: $c_sale;

        svg {
          rect,
          path {
            fill: $c_white;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .text {
        position: absolute;
        left: 100%;
        height: 100%;
        top: 0;
        background-color: transparent;
        display: none;
        border-bottom-right-radius: $b_radius_default;
        border-top-right-radius: $b_radius_default;
      }

      img {
        transition: all 0.2s;
        margin-right: 10px;
      }

      &:hover {
        background-color: $c_black_bg;

        img {
          transform: rotate(25deg);
        }

        svg:last-child {
          path {
            fill: $c_white;
          }
        }

        .text {
          width: 190px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $text_white;
          background-color: $c_black_bg;

          //span {
          //  margin-left: 10px;
          //}

          svg {
            margin-right: 20px;
          }
        }
      }

      &.hasMegamenu {
        .megamenu {
          display: none;
          flex-wrap: wrap;
          width: 1311px;
          padding: 25px;
          position: absolute;
          left: calc(100% + 10px);
          top: 56px;
          background-color: $c_white_bg;
          box-shadow: 0 3px 5px $c_shadow;
          border-bottom-right-radius: $b_radius_default;
          border-bottom-left-radius: $b_radius_default;
          overflow-y: auto;

          .categoryList {
            margin-right: 80px;
            margin-bottom: 60px;

            h4 {
              display: flex;
              align-items: center;
              margin-bottom: 15px;

              svg,
              img {
                margin-right: 10px;
              }

              svg {
                transform: none !important;

                path {
                  fill: $c_black;
                }
              }
            }

            ul {
              padding-left: 45px;

              li {
                width: auto;
                height: auto;

                &:hover {
                  background-color: transparent;

                  a {
                    text-decoration: underline;
                  }
                }

                a {
                  padding: 2px 0;
                  font-size: $f_size_base;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}

section.promo {
  background-color: $c_main_bg;
  padding-top: 40px;
  padding-bottom: 10px;

  .grid {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 -5px;

    .categoryWidget {
      width: 20%;
      padding: 0 5px;

      @media (max-width: $xxxl) {
        width: 25%;
      }

      ul.categoryWidgetList {
        width: 100%;
        background-color: $c_white_bg;
        border-radius: $b_radius_default;
        box-shadow: 0 0 5px $c_shadow;

        li.categoryWidgetItem {
          border-top-left-radius: $b_radius_default;
          border-bottom-left-radius: $b_radius_default;
          width: 100%;
          transition: all 0.2s ease;
          height: 56px;
          display: flex;
          align-items: center;

          a.mainCategoryLink {
            img {
              transition: all 0.2s;
              margin-right: 10px;
            }
          }

          &:hover {
            background-color: $c_black;

            a.mainCategoryLink {
              color: $c_white;

              img {
                transform: rotate(25deg);
              }

              // svg:first-child {
              //   transform: rotate(25deg);

              //   path {
              //     fill: $c_white;
              //   }
              // }

              svg:last-child {
                path {
                  fill: $c_white;
                }
              }
            }
          }

          a {
            width: 100%;
            color: $c_black;
            font-size: $f_size_base;
            font-weight: bold;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 17px 20px;
            transition: color 0.2s ease;

            svg {
              width: 35px;

              &:first-child {
                transition: transform 0.2s ease;
                margin-right: 18px;

                path {
                  transition: fill 0.2s ease;
                }
              }

              &:last-child {
                margin-left: auto;

                path {
                  fill: $c_black;
                }
              }
            }
          }

          &.discount {
            color: $text_white;
            background-color: $c_sale;
            border-radius: $b_radius_default;

            &:hover {
              background-color: $c_black_bg;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }

            a {
              color: inherit;
            }

            svg {
              &:first-child {
                path,
                rect {
                  fill: $c_white;
                }
              }

              &:last-child {
                path {
                  fill: $c_white;
                }
              }
            }
          }

          &.hasMegamenu {
            position: relative;

            &:hover {
              & {
                width: calc(100% + 10px);
              }

              .megamenu {
                display: flex;
              }
            }

            .megamenu {
              display: none;
              flex-wrap: wrap;
              padding: 25px;
              //width: 100%;
              width: calc(1306px - 50px);
              background-color: $c_white_bg;
              position: absolute;
              left: 100%;
              top: 0;
              z-index: 100;
              border-top-right-radius: $b_radius_default;
              border-bottom-right-radius: $b_radius_default;
              border-bottom-left-radius: $b_radius_default;

              .categoryList {
                margin-right: 80px;
                margin-bottom: 60px;

                h4 {
                  display: flex;
                  align-items: center;
                  margin-bottom: 15px;

                  svg,
                  img {
                    margin-right: 10px;
                  }
                }

                ul {
                  padding-left: 45px;

                  li {
                    a {
                      max-width: 150px;
                      padding: 2px 0;
                      font-size: $f_size_base;
                      font-weight: normal;

                      &:hover {
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .promoSlider {
      width: 60%;
      padding: 0 5px;
      overflow: hidden;
      position: relative;
      @media (max-width: $xxxl) {
        width: 75%;
      }
      .slide {
        position: relative;
        a {
          display: block;
        }
        .slideImage {
          width: 100%;
          display: block;

          img {
            display: block;
            width: 100%;
            max-width: 100%;
            border-radius: $b_radius_default;
          }
        }

        .text {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);

          h3 {
            color: $text_white;
            font-size: 60px;
            line-height: 60px;
            margin-bottom: 15px;
          }
        }

        :global(.button) {
          position: absolute;
          bottom: 20px;
          left: 20px;
          background-color: white;
          display: inline-block;
          height: 40px;
          line-height: 40px;
          color: #000;
          background-color: #fff;
          border: 1px solid #fff;
          box-sizing: border-box;
          font-size: 16px;
          font-weight: bold;
          border-radius: 10px;
          color: rgb(0, 0, 0);
          padding: 0 12px;
          transition: all 0.2s;
          img {
            transition: all 0.2s;
            display: inline-block;
            width: 13px;
            height: 10px;
            margin-left: 10px;
          }
        }

        &:hover {
          :global(.button) {
            background-color: transparent;
            color: white;
            img {
              filter: grayscale(1) invert(1);
              transform: translateX(5px);
            }
          }
        }
      }
    }

    .adBanner {
      width: 20%;
      padding: 0 5px;
      position: relative;

      @media (max-width: $xxxl) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: $b_radius_default;
      }

      .text {
        position: absolute;
        left: 20px;
        bottom: 20px;

        h3 {
          color: $text_white;
          font-size: 40px;
          line-height: 40px;
          font-weight: bold;
          margin-bottom: 30px;
        }

        :global(.button) {
          background-color: white;
          display: inline-block;
          height: 40px;
          line-height: 40px;
          color: #000;
          background-color: #fff;
          border: 1px solid #fff;
          box-sizing: border-box;
          font-size: 16px;
          font-weight: bold;
          border-radius: 10px;
          color: rgb(0, 0, 0);
          padding: 0 12px;
          transition: all 0.2s;
          svg {
            transition: all 0.2s;
            display: inline-block;
            width: 13px;
            height: 10px;
            margin-left: 10px;
          }
        }
      }

      &:hover {
        .text {
          :global(.button) {
            background-color: transparent;
            color: white;
            svg {
              fill: white;
              transform: translateX(5px);
            }
          }
        }
      }
    }
  }
}

.miniBanners,
.miniBannersMobile {
  padding-top: 10px;

  .gridMiniBanners {
    padding-left: 20%;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    margin: 0 -5px;

    @media (max-width: $xxxl) {
      padding-left: 0;
    }
  }

  .miniBanner {
    position: relative;
    z-index: 0;
    overflow: hidden;
    border-radius: $b_radius_default;
    width: 25%;
    padding: 0 5px;

    a {
      display: block;
    }
    picture {
      display: inline-block;
      border-radius: $b_radius_default;
      overflow: hidden;
      width: 100%;
    }

    &:hover {
      img {
        transform: scale(1.05);
      }

      .svg {
        opacity: 1;
      }
    }

    img {
      width: 100%;
      border-radius: $b_radius_default;
      transition: all 0.2s ease;
    }

    .svg {
      position: absolute;
      right: 15px;
      top: 50%;
      opacity: 0;
      transition: opacity 0.2s ease;
      width: 20px;

      path {
        fill: $c_white;
      }
    }

    .text {
      color: $text-white;
      position: absolute;
      left: 33px;
      bottom: 20px;

      h5 {
        font-size: 30px;
        line-height: 30px;
        color: inherit;
        margin-bottom: 25px;
      }

      span {
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        display: block;
      }
    }
  }
}

section.miniBannersMobile {
  display: none;
}

section.productSliderWrapper {
  padding-top: 35px;

  .box {
    background-color: $c_white_bg;
    width: 290px;
    padding: 20px;
    box-shadow: 0 0 5px $c_shadow;
    margin-right: 15px;
    position: relative;
    border-radius: $b_radius_default;

    .badges {
      position: absolute;
      left: -10px;
      top: 20px;
      z-index: 10;
      overflow: hidden;

      div {
        display: flex;
        align-items: center;
        padding: 0 13px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-bottom: 10px;
        position: relative;

        span {
          color: $text_white;
          font-size: 13px;
          //line-height: 13px;
          font-weight: bold;
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 10px;
          height: 26px;
          z-index: 1;
          background: $c_main_bg;
          transform: skew(15deg, 0deg) translateX(-4px);
        }
      }

      .badgeNew {
        max-width: 75px;
        background-color: $c_blue_badge;
      }

      .favourite {
        max-width: 80px;
        background-color: $c_favourite_badge;
      }

      .delivery {
        padding-right: 10px;
        background-color: $c_info_badge;

        svg {
          fill: $c_white;
          margin-right: 5px;

          path,
          rect {
            fill: $c_white;
          }
        }
      }
    }

    .productThumb {
      display: block;
      text-align: center;
      position: relative;

      .productDiscountBadge {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .infoBarWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .infoStock,
      .infoStockMobile {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 15px;
        font-weight: bold;
        color: $text_red_stock;
        text-transform: uppercase;

        &:before {
          content: "●";
          font-size: 10px;
          margin-right: 3px;
        }
      }

      .infoStockMobile {
        display: none;
      }

      .productActions {
        span {
          margin-right: 6px;

          svg {
            fill: #d9d9d9;
          }

          img,
          svg {
            transition: all 0.2s ease;
          }

          &:hover {
            img,
            svg {
              transform: rotate(-25deg);
            }

            &:nth-child(1) {
              svg {
                fill: $c_black;
              }
            }

            &:nth-child(2) {
              svg {
                fill: #ff0000;
              }
            }
          }
        }
      }
    }

    h4 {
      color: $text_black;
      font-size: 20px;
      line-height: 20px;
      margin-top: 10px;
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: $text_black;
      opacity: 0.5;
      margin-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    }

    .priceWrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .mainPrice {
        display: flex;
        flex-direction: column;

        span {
          &:first-child {
            font-size: 30px;
            font-weight: bold;
            line-height: 34px;
            color: $text_price;
          }

          &:last-child {
            font-size: 14px;
            line-height: 34px;
            color: $text_black;
            opacity: 0.5;
          }
        }
      }

      .discountPrice {
        span {
          display: block;
          font-size: 20px;
          line-height: 34px;
          color: $text_black;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            top: 55%;
            background-color: $text_black;
          }
        }
      }
    }
  }
}

section.bannerOffer {
  padding: 58px 0 110px;
  background-color: $c_main_bg;

  .sectionTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h2 {
      font-size: 40px;
      font-weight: bold;
      line-height: 50px;
      margin-bottom: 10px;
    }
  }

  .bannerWrapper {
    margin-top: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .bannerOfferImageWrapper {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;

      .image {
        display: inline-block;
        position: relative;

        .discountBadge {
          width: 122px;
          height: 122px;
          right: 10%;
          top: -20px;
          position: absolute;

          span {
            font-size: 20px;
            line-height: 33px;
          }

          strong {
            font-size: 41px;
            line-height: 34px;
          }
        }
      }
    }

    .first,
    .second {
      height: 300px;
    }

    .first {
      padding-left: 20px;
      background-color: $c_black_bg;
      border-radius: $b_radius_default 0 0 $b_radius_default;
      width: 50%;

      h3 {
        font-size: 40px;
        font-weight: normal;
        line-height: 50px;
        color: $text_white;
        margin-bottom: 5px;
        margin-top: 20px;
      }

      p {
        max-width: 50%;
        color: $text_gray_darker;
        font-size: 16px;
        line-height: 20px;
        margin-top: 0;
      }

      p.textAlert {
        max-width: 100%;
        color: $text_danger;
      }

      div.prices {
        display: flex;
        align-items: center;
        margin-top: 20px;
        position: relative;
        z-index: 5;

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: $b_radius_default;

          span {
            &:first-child {
              font-size: 14px;
              line-height: 28px;
              font-weight: bold;
              margin-bottom: 15px;
            }

            &:last-child {
              font-size: 50px;
              font-weight: bold;
            }
          }
        }

        .pricesMain {
          padding: 10px 34px 28px;
          margin-right: 10px;
          background-color: $c_white_bg;

          span {
            color: $text_black;

            &:last-child {
              position: relative;

              &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 55%;
                width: 100%;
                height: 3px;
                background-color: $c_black;
              }
            }
          }
        }

        .pricesDiscount {
          padding: 10px 37px 28px;
          background-color: $c_red_bg;

          span {
            color: $text_white;
          }
        }
      }
    }

    .second {
      width: 50%;
      background-color: $c_green_bg;
      border-radius: 0 $b_radius_default $b_radius_default 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .wrapper {
        padding-left: 30%;
      }

      h5 {
        font-size: 30px;
        font-weight: bolder;
        line-height: 50px;
        text-align: center;
      }

      .countdown {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          display: flex;
          flex-direction: column;

          span {
            color: $text_black;

            &:first-child {
              font-size: 70px;
              font-weight: bold;
              margin-bottom: 2px;
              width: 98px;
              height: 92px;
              line-height: 92px;
              text-align: center;
              margin-right: 10px;
              border: 1px solid $c_black_bg;
              border-radius: $b_radius_default $b_radius_default 0 0;
            }

            &:last-child {
              height: 50px;
              font-size: 40px;
              line-height: 50px;
              color: $text_white;
              width: 98px;
              border: 1px solid $c_black_bg;
              text-align: center;
              background-color: $c_black_bg;
              border-radius: 0 0 $b_radius_default $b_radius_default;
            }
          }
        }

        .seconds {
          span {
            &:first-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

section.bannerLinks {
  padding: 50px 0;

  .bannerLinksGrid {
    .bannerText {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h5 {
        font-size: 40px;
        color: $text_white;
        text-shadow: 0 3px 6px #00000029;
        margin-bottom: 0;
        margin-top: 30px;
        margin-left: 20px;
      }

      span {
        display: inline-flex;
        align-items: center;
        margin-bottom: 20px;
        margin-left: 20px;

        img {
          width: 14px !important;
          height: 10px !important;
          transition: all 0.2s ease;
          filter: grayscale(1) invert(1);
        }

        &:hover {
          img {
            filter: grayscale(0) invert(0);
          }
        }
      }
    }

    &:first-child {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;

      div.box {
        border-radius: $b_radius_default;
        position: relative;
        overflow: hidden;
        width: 33%;
        margin-left: 10px;

        img {
          width: 100%;
          height: 100%;
          border-radius: $b_radius_default;
        }

        &:nth-child(1) {
          display: flex;
          align-items: center;
          text-align: center;
          margin-left: 0;

          h3 {
            font-size: 40px;
            line-height: 50px;
            margin: 0;
            text-align: left;
          }
        }
      }
    }

    &:last-child {
      display: flex;

      div.box {
        border-radius: $b_radius_default;
        width: 50%;
        margin-left: 10px;
        position: relative;

        &:first-child {
          margin-left: 0;
        }

        img {
          border-radius: $b_radius_default;
        }
      }
    }
  }
}

.brands {
  padding: 50px 0;
  background-color: $c_white_bg;

  .brandsWrapper {
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //flex-wrap: wrap;
  }

  img {
    margin-right: 22px;
  }
}

.testimonialsSection {
  :global(.testimonials) {
    padding: 50px 0;
    background-color: $c_white_bg;
  }

  :global(.testimonial) {
    @media (min-width: $lg) {
      max-width: 50%;
    }
  }

  :global(.rating) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    svg,
    img {
      margin-right: 5px;
    }
  }
}

@media (max-width: $xxxl) {
  section.bannerOffer {
    .bannerWrapper {
      .second {
        .wrapper {
          padding-left: 40%;
        }

        .countdown {
          div {
            span {
              &:first-child {
                font-size: 40px;
                width: 60px;
                height: 70px;
                line-height: 70px;
              }

              &:last-child {
                font-size: 30px;
                width: 60px;
              }
            }
          }

          .days {
          }

          .hours {
          }

          .minutes {
          }

          .seconds {
            span {
              &:first-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  section.promo {
    .grid {
      .categoryWidget {
        ul.categoryWidgetList {
          width: 100%;
          background-color: $c_white_bg;
          border-radius: $b_radius_default;
          box-shadow: 0 0 5px $c_shadow;

          li.categoryWidgetItem {
            a {
              svg {
                width: 40px;
              }
            }

            &.hasMegamenu {
              .megamenu {
                width: calc(1011px - 50px);
              }
            }
          }
        }
      }

      .promoSlider {
      }

      .adBanner {
      }
    }
  }
}

@media (max-width: $xxl) {
  section.bannerOffer {
    .bannerWrapper {
      .bannerOfferImageWrapper {
        .image {
          .discountBadge {
            width: 90px;
            height: 90px;
            top: 0;

            span {
              font-size: 18px;
              line-height: 1.2;
            }

            strong {
              font-size: 28px;
              line-height: 1.2;
            }
          }
        }
      }

      .second {
        display: flex;
        align-items: center;

        h5 {
          margin-top: 0;
        }

        .wrapper {
          padding-left: 50%;
        }

        .countdown {
          div {
            span {
              &:first-child {
                font-size: 30px;
                width: 50px;
                height: 55px;
                line-height: 55px;
              }

              &:last-child {
                font-size: 25px;
                width: 50px;
                height: 30px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }

  section.promo {
    .grid {
      width: auto;

      .categoryWidget {
        ul.categoryWidgetList {
          li.categoryWidgetItem {
            &.hasMegamenu {
              .megamenu {
                width: calc(874px - 50px);
              }
            }
          }
        }
      }
    }

    .miniBanners {
      .gridMiniBanners {
        width: auto;
      }
    }
  }
}

@media (max-width: $xl) {
  section.promo {
    .grid {
      width: auto;

      .categoryWidget {
        ul.categoryWidgetList {
          li.categoryWidgetItem {
            height: 45px;
            & > a {
              font-size: 0.8em;
              padding: 10px 10px;
              picture {
                width: 35px;
                margin-right: 15px;
              }
            }

            &.hasMegamenu {
              .megamenu {
                width: calc(738px - 50px);
              }
            }
          }
        }
      }
    }
  }

  section.bannerOffer {
    padding: $general-gutter * 3 0;
    .sectionTitle {
      justify-content: space-between;
      h2 {
        font-size: 1.5em;
      }
    }

    .bannerWrapper {
      flex-direction: column;

      .bannerOfferImageWrapper {
        border-radius: $b_radius_default;
        padding: $general-gutter;
        margin-bottom: $general-gutter;
        position: relative;
        background-color: white;
        .image {
          .discountBadge {
            width: 65px;
            height: 65px;
            left: auto;
            right: 0;
            span {
              font-size: 0.7em;
              strong {
                font-size: 1.5em;
              }
            }
          }
        }
      }

      .first,
      .second {
        width: 100%;
      }

      .first {
        padding-left: 0;
        border-radius: $b_radius_default;
        padding: $general-gutter;
        text-align: center;
        height: auto;
        h3 {
          margin: 0;
          font-size: 1.8em;
          line-height: 1.9em;
          //  padding-left: 20px;
        }

        p {
          max-width: 100%;
          margin: $general-gutter 0;
          //  padding-left: 20px;
        }

        div.prices {
          padding: 0;
          margin: 0;
          justify-content: space-between;
          .pricesMain,
          .pricesDiscount {
            width: 49%;
            padding: $general-gutter 0;
            margin: 0;
            font-size: 0.8em;
            & > span:first-child {
              margin: 0;
            }
            & > span:last-child {
              font-size: 2em;
            }
          }
          //  padding-left: 20px;
        }
      }

      .second {
        margin-top: $general-gutter;
        border-radius: $b_radius_default;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        padding: $general-gutter;
        & > img {
          margin-top: -20px;
        }
        .wrapper {
          width: auto;
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  section.bannerLinks {
    .bannerLinksGrid {
      &:first-child {
        div.box {
          &:nth-child(1) {
            h3 {
              font-size: 30px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1750px) {
  .categoryWidgetFixed {
    display: none;
  }
}

@media (max-width: $lg) {
  section.promo {
    //padding-top: 20px;

    .grid {
      .categoryWidget {
        display: none;
      }

      .promoSlider {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .miniBanners {
      display: none;
    }
  }

  section.miniBannersMobile {
    display: block;

    .miniBanner {
      width: 100%;
      padding-left: 0;
      padding-right: 10px;
    }
  }

  section.productSliderWrapper {
    .box {
      width: 180px;

      .badges {
        div {
          span {
            font-weight: normal;
          }
        }
      }

      .infoBarWrapper {
        .infoStockMobile {
          display: flex;
          font-size: 10px;
          line-height: 7px;

          &:before {
            font-size: 10px;
            margin-right: 3px;
          }
        }

        .infoStock {
          display: none;
        }

        .productActions {
        }
      }

      h4 {
        font-size: 16px;
        line-height: 17px;
      }

      p {
        font-size: 10px;
        line-height: 15px;
      }

      .priceWrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 6px;

        .mainPrice {
          display: flex;
          flex-direction: column;

          span {
            &:first-child {
              font-size: 16px;
              line-height: 17px;
            }

            &:last-child {
              font-size: 10px;
              line-height: 15px;
            }
          }
        }

        .discountPrice {
          span {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }

  section.bannerLinks {
    .bannerLinksGrid {
      &:first-child {
        flex-wrap: wrap;

        div.box {
          margin-left: 0;

          &:nth-child(1) {
            flex-basis: 100%;

            h3 {
              font-size: 20px;
              line-height: 25px;
              margin-bottom: 10px;
            }
          }

          &:not(:first-child) {
            flex: 1;
            width: 50%;
            margin-right: 10px;
          }

          &:last-child {
            margin-right: 0;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &:last-child {
        div.box {
          width: 50% img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  section.testimonials {
    .testimonial {
      max-width: 100%;
      margin: auto;

      .rating {
      }
    }
  }
}

@media (max-width: $md) {
  section.testimonials {
    .testimonial {
      max-width: 100%;
      margin: auto;

      .rating {
        margin-bottom: 30px;
      }
    }
  }

  div.categoryTabs {
    .categoryTabsWrapper {
      &:after {
        display: block;
      }

      .tabArrow {
        display: block;
        position: absolute;
        z-index: 11;
        right: 20px;
        top: 40%;
      }

      ul {
        overflow: auto;
        white-space: nowrap;
        flex-wrap: nowrap;
      }
    }
  }

  section.bannerOffer {
    // display: none;
  }

  section.bannerLinks {
    .bannerLinksGrid {
      .box {
        h5 {
          font-size: 22px;
          line-height: 27px;
        }

        span {
          display: none;
        }
      }
    }
  }
}

@media (max-width: $sm) {
  section.productSliderWrapper {
    .box {
      width: 180px;

      .infoBarWrapper {
        svg {
          width: 18px;
        }
      }

      .badges {
        div {
          margin-bottom: 5px;
          padding-right: 8px;

          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  section.bannerLinks {
    padding-top: 30px;
  }

  section.promo {
    .grid {
      .promoSlider {
        .slide {
          .text {
            height: 100%;
            left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h3 {
              font-size: 40px;
              line-height: 40px;
              margin-top: 20px;
            }

            a {
              height: 35px;
              line-height: 35px;
              font-size: 14px;
              max-width: 135px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $xs) {
  section.bannerLinks {
    padding-top: 30px;
  }
}

@media (max-width: 350px) {
  section.bannerLinks {
    .bannerLinksGrid {
      .box {
        h5 {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }
}
