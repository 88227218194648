@import "styles/variables";

.box {
  background-color: $c_white_bg;
  width: 290px;
  padding: 20px;
  box-shadow: 0 0 5px $c_shadow;
  margin-right: 15px;
  position: relative;
  border-radius: $b_radius_default;

  a {
    display: block;
  }

  .badges {
    position: absolute;
    left: -10px;
    top: 20px;
    z-index: 10;
    overflow: hidden;

    div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      position: relative;
      padding-left: 10px;

      span {
        color: $text_white;
        font-size: 13px;
        padding: 0 13px;
        font-weight: bold;
        display: block;
        -webkit-border-top-right-radius: 10px;
        -webkit-border-bottom-right-radius: 10px;
        -moz-border-radius-topright: 10px;
        -moz-border-radius-bottomright: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &:before {
        content: "";
        position: absolute;
        left: 3px;
        top: 0;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 26px 0;
        border-color: transparent transparent transparent transparent;
      }
    }

    .badgeNew {
      span {
        background-color: $c_blue_badge;
      }
      &:before {
        border-color: transparent $c_blue_badge transparent transparent;
      }
    }

    .badgeFavourite {
      span {
        background-color: $c_favourite_badge;
      }
      &:before {
        border-color: transparent $c_favourite_badge transparent transparent;
      }
    }

    .badgeStatus {
      span {
        background-color: $c_status_badge;
      }
      &:before {
        border-color: transparent $c_status_badge transparent transparent;
      }
    }

    .badgeDelivery {
      span {
        background-color: $c_info_badge;
        svg {
          fill: $c_white;
          margin-right: 5px;
          margin-bottom: -3px;

          path,
          rect {
            fill: $c_white;
          }
        }
      }
      &:before {
        border-color: transparent $c_info_badge transparent transparent;
      }
    }
  }

  .productThumb {
    display: block;
    text-align: center;
    position: relative;

    .productDiscountBadge {
      position: absolute;
      right: 0;
      top: 0;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0px 0px 0px 7px rgba(248, 0, 0, 0.5);
      }
    }
  }

  .infoBarWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .infoStock,
    .infoStockMobile {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 15px;
      font-weight: bold;
      color: $text_red_stock;
      text-transform: uppercase;

      &:before {
        content: "●";
        font-size: 10px;
        margin-right: 3px;
      }
    }

    .infoStockMobile {
      display: none;
    }

    .productActions {
      span {
        margin-right: 6px;

        svg {
          fill: #d9d9d9;
        }

        img,
        svg {
          transition: all 0.2s ease;
        }

        &:hover {
          img,
          svg {
            transform: rotate(-25deg);
          }

          &:nth-child(1) {
            svg {
              fill: $c_black;
            }
          }

          &:nth-child(2) {
            svg {
              fill: #ff0000;
            }
          }
        }
      }
    }
  }

  .contentCentered {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h4 {
    color: $text_black;
    font-size: 20px;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    height: 60px;
    overflow: hidden;
  }

  .content {
    margin-top: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    display: block;
    position: relative;
    margin-bottom: 10px;
    div,
    p {
      margin: 0;
      padding: 0;
    }
    & > p {
      height: 80px;
      overflow: hidden;
      font-size: 14px;
      line-height: 20px;
      color: $text_black;
      opacity: 0.5;
      margin-bottom: 10px;
    }
  }

  .priceWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .mainPrice {
      display: flex;
      flex-direction: column;

      span {
        &:first-child {
          font-size: 20px;
          font-weight: bold;
          line-height: 22px;
          color: $text_price;
        }

        &:last-child {
          font-size: 14px;
          line-height: 34px;
          color: $text_black;
          opacity: 0.5;
        }
      }
    }

    .discountPrice {
      span {
        display: block;
        font-size: 18px;
        line-height: 22px;
        color: $text_black;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          top: 55%;
          background-color: $text_black;
        }
      }
    }
  }

  @media (max-width: $lg) {
    width: 180px;
    padding: 10px;

    .badges {
      top: 10px;
      div {
        margin-bottom: 5px;
        span {
          font-weight: normal;
          font-size: 8px;
          line-height: 17px;
          padding: 0 7px 0 3px;
        }
        &:before {
          border-width: 0 7px 17px 0;
        }
      }
      .badgeDelivery {
        span {
          svg {
            width: 16px;
          }
        }
      }
    }

    .infoBarWrapper {
      .infoStockMobile {
        display: flex;
        font-size: 10px;
        line-height: 7px;

        &:before {
          font-size: 10px;
          margin-right: 3px;
        }
      }

      .infoStock {
        display: none;
      }

      .productActions {
        span {
          svg {
            width: 18px;
          }
        }
      }
    }

    h4 {
      font-size: 14px;
      line-height: 17px;
      height: 34px;
    }

    .content {
      & > p {
        height: 30px;
        font-size: 10px;
        line-height: 15px;
      }
    }

    .priceWrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 6px;

      .mainPrice {
        display: flex;
        flex-direction: column;

        span {
          &:first-child {
            font-size: 18px;
            line-height: 18px;
          }

          &:last-child {
            font-size: 10px;
            line-height: 15px;
          }
        }
      }

      .discountPrice {
        span {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  @media (max-width: $xxs) {
    width: 160px;
  }
}
