@import "styles/variables";

section.testimonials {
  .testimonial {
    display: block !important;
    margin: auto;

    h5 {
      font-size: $f_size_base;
      font-weight: bold;
      line-height: 18px;
      margin-bottom: 20px;
      margin-top: 0;
    }

    .description {
      font-size: $f_size_base;
      line-height: 20px;
      font-weight: normal;
      text-align: center;
    }
  }
}
