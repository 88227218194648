@import "styles/variables";

.blogPostBox {
  background-color: $c_white_bg;
  box-shadow: 0 0 5px $c_shadow;
  margin-bottom: 15px;
  position: relative;
  border-radius: $b_radius_default;
  
  &:hover {

    .blogPostThumb {

      &:after {
        background-color: rgba(0, 0, 0, .6);
      }

      .image:last-child {
        opacity: 1;
      }
    }
  }

  .blogPostBadge {
    color: $text_white;
    font-size: 14px;
    line-height: 18px;
    background-color: $c_red_bg;
    border-radius: $b_radius_default;
    padding: 5px 16px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
    box-shadow: 0 0 10px $c_black;
    z-index: 5;
  }

  .blogPostThumb {
    border-top-left-radius: $b_radius_default;
    border-top-right-radius: $b_radius_default;
    position: relative;

    &:after {
      content: '';
      border-top-left-radius: $b_radius_default;
      border-top-right-radius: $b_radius_default;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: transparent;
      transition: background-color .2s ease;
    }

    .image:first-child {
      border-top-left-radius: $b_radius_default;
      border-top-right-radius: $b_radius_default;
      img{
        border-top-left-radius: $b_radius_default;
        border-top-right-radius: $b_radius_default;
      }
    }

    .image:last-child {
      z-index: 10;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 50%;
      opacity: 0;
      transition: opacity .2s ease;
      transform: scale(2);
    }
  }

  .text {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .postTitle {

      h4 {
        font-size: 25px;
        line-height: 34px;
        height: 68px;
        overflow: hidden;
        margin-top: 0;
        margin-bottom: 0;
      }

      .meta {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .comments {
          display: flex;
          align-items: center;

          svg {

            path {
              fill: $text_light_gray;
            }
          }

          span {
            position: relative;
            top: -2px;
            font-size: $f_size_base;
            font-weight: bold;
            color: $text_light_gray;
            margin-left: 5px;
          }
        }

        time {
          color: $text_light_gray;
          font-size: $f_size_base;
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 21px;
      border-bottom: none !important;
      opacity: 1 !important;
      height: 84px;
      overflow: hidden;
    }
  }
}